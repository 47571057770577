/* FloatingLabelInput.css */
.floating-label-input {
    position: relative;
    margin-bottom: 20px;
    width: 100%;
  }
  
  .floating-label-input label {
    position: absolute;
    top: 50%;
    left: 10px;
    transform: translateY(-50%);
    transition: all 0.3s ease;
    background-color: white;
    padding: 0 5px;
    pointer-events: none;
    font-size: 14px;
    opacity: 0.8;
    z-index: 1;
  }
  
  .floating-label-input.focused label,
  .floating-label-input input:focus + label,
  .floating-label-input input:not(:placeholder-shown) + label,
  .floating-label-input .ant-select-focused + label,
.floating-label-input .ant-select:not(.ant-select-customize-input).ant-select-open + label,
.floating-label-input .ant-select:not(.ant-select-customize-input).ant-select-focused + label {
    top: 0;
    left: 10px;
    font-size: 13px;
    font-weight: 500;
    opacity: 1;
    z-index: 11;
  }
  
  .floating-label-input input,
  .floating-label-input .ant-select {
    width: 100%;
    padding: 10px 14px;
    outline: none;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    transition: border-color 0.3s;
  }
  
  .floating-label-input input:focus,
  .floating-label-input .ant-select-focused {
    border-color: #40a9ff;
  }

  button.ant-btn {
    background: #6612f0;
    border-radius: 5px;
    font-size: 14px;
}
  
button.ant-btn:hover {
  background: #6612f0 !important;
}

.ant-btn-primary:disabled {
  background: #6612f07a;
  color: #eee;
}